@charset "utf-8";

body.theme-dark {
	color: #FFF;
	background-color: #38394B;

}



body.theme-dark .btn13{
	color: #38394b;
	background-color: #ffffff;
}

body.theme-dark .btn-big{
	color: #38394b;
	background-color: #ffffff;
}
body.theme-dark .btn-green{
	color:#ffffff;
	background-color: #52bc75;
}

body.theme-dark .btn-menu{
	background: transparent url("/assets/ic/menu-white.png") no-repeat right center;
	background-size: 17px auto;
}

body.theme-dark .hp-wrapper h1,
body.theme-dark .hp-wrapper h3{
	color:#ffffff;
}




body.theme-dark footer{
	box-shadow: none;
	border-top:1px solid #4D4E64;
	background-color: #47485F;
}
body.theme-dark .footer-systemstat{
	border-right: 1px solid #505167;
}
body.theme-dark .footer-toggle-theme button{
	background: transparent url("/assets/ic/theme-night.png") no-repeat 0 0;
	background-size: 17px auto;
}