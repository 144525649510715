@charset "utf-8";

html {
	height: 100%;
}

body {
	height: 100%;
	font-family: 'Roboto', sans-serif, Arial;
	color: #8d9294;
	background-color: #f6f6f8;
}

* {
	margin: 0;
	padding: 0;
}

a, img {
	border: none;
	outline: none;
}

button {
	outline: 0;
}

input {
	padding: 8px;
	color: #38394b;
	border-color: #d8d8da;
	line-height: 1;
	outline: 0;
	font-size: 14px;
	background: #f8f8f9;
	background: -moz-linear-gradient(top, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	background: -webkit-linear-gradient(top, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	background: linear-gradient(to bottom, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8f8f9', endColorstr='#ffffff', GradientType=0);
}

textarea {
	padding: 8px;
	color: #38394b;
	border-color: #d8d8da;
	line-height: 1;
	outline: 0;
	font-size: 14px;
	font-family: 'Roboto', sans-serif, Arial;
	background: #f8f8f9;
	background: -moz-linear-gradient(top, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	background: -webkit-linear-gradient(top, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	background: linear-gradient(to bottom, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8f8f9', endColorstr='#ffffff', GradientType=0);
}

select {
	display: inline-block;
	padding: 8px;
	color: #38394b;
	border-color: #d8d8da;
	line-height: 1;
	outline: 0;
	font-size: 14px;
	font-family: 'Roboto', sans-serif, Arial;
	background: #f8f8f9;
	background: -moz-linear-gradient(top, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	background: -webkit-linear-gradient(top, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	background: linear-gradient(to bottom, #f8f8f9 0%, #f8f8f9 8%, #ffffff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8f8f9', endColorstr='#ffffff', GradientType=0);
	cursor: pointer;
}

.text-btn {
	background: none;
	border: none;
	cursor: pointer;
}

.system-error {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	color: red;
}

.page-404 {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	color: #000;
}

.page-404 a {
	display: block;
	width: 100%;
	padding-top: 20px;
	color: #38394b;
	font-size: 13px;
}

.page-404 a:hover {
	text-decoration: none;
}

.ic-info {
	display: inline-block;
	width: 13px;
	height: 16px;
	margin-right: 9px;
	background: transparent url("/assets/ic/info.png") no-repeat 0 0;
	background-size: 13px auto;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #8e9395;
}

input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
textarea:-moz-placeholder {
	color: #8e9395;
	opacity: 1;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #8e9395;
}

.inp {
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #d8d8da;
	background: #ffffff;
	background: linear-gradient(180deg, #f6f6f7 0px, #ffffff 3px);
}

.sp-form-button {
	display: inline-block;
	width: 100%;
	box-sizing: border-box;
	line-height: 1;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 13px;
	font-weight: 700;
	color: #ffffff;
	background: #38394b none;
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	cursor: pointer;
	transition: opacity 100ms ease-in-out;
}

.sp-form-button[disabled=disabled],
.sp-form-button:disabled {
	cursor: no-drop;
	background: #eeeef0 none;
	transition: none;
}

.sp-form-button:hover {
	opacity: 0.9;
	transition: opacity 100ms ease-in-out;
}

.inp.valid {
	border: 1px solid #8ed3a5;
	background: #ffffff url("/assets/ic/valid-check.png") no-repeat 95% center;
	background-size: 10px auto;
}

.inp.invalid {
	border: 1px solid #f39c90;
	background: #ffffff url("/assets/ic/invalid-x.png") no-repeat 95% center;
	background-size: 10px auto;
}

.inp.valid[type=checkbox] {
	box-shadow: 1px 1px 2px #8ed3a5;
}

.inp.invalid[type=checkbox] {
	box-shadow: 1px 1px 2px #f39c90;
}

h1.page-title{
	display: block;
	width: 100%;
	line-height: 1.1;
	padding-bottom: 5px;
	margin-top:25px;
	color: #38394b;
	font-size: 33px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	text-align: center;
	white-space: nowrap;
}

h2.page-title{
	display: block;
	width: 100%;
	line-height: 1.2;
	padding-bottom: 30px;
	margin-top:35px;
	color: #38394b;
	font-size: 23px;
	text-align: center;
}

h3.page-title{
	display: block;
	width: 100%;
	line-height: 1.2;
	padding-bottom: 10px;
	color: #38394b;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-weight: 300;
	font-size: 21px;
	text-align: center;

}

.btn13 {
	display: inline-block;
	box-sizing: border-box;
	padding-left: 39px;
	padding-right: 39px;
	height: 32px;
	line-height: 32px;
	font-size: 14px;
	font-family: Roboto, sans-serif, Arial, Verdana;
	font-weight: 500;
	color: #ffffff;
	background-color: #38394b;
	border: 0;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	transition: color 100ms ease-in-out;
}

.btn13:hover {
	color: #CCC;
	transition: color 100ms ease-in-out;
}

.btn-menu {
	display: inline-block;
	box-sizing: border-box;
	width: 17px;
	height: 32px;
	margin-left: 30px;
	background: transparent url("/assets/ic/menu-black.png") no-repeat right center;
	background-size: 17px auto;
	transition: opacity 100ms ease-in-out;
}

.btn-menu:hover {
	opacity: 0.8;
	transition: opacity 100ms ease-in-out;
}

.btn-menu-close {
	display: inline-block;
	box-sizing: border-box;
	width: 32px;
	height: 32px;
	line-height: 32px;
	color: #BCBCC2;
	margin-left: 15px;
	font-size: 20px;
	font-weight: 500;
	font-family: Arial, Verdana, Sans, serif;
	transition: color 200ms ease-in-out;
	text-align: right;
}

.btn-menu-close:hover {
	color: #38394b;
	transition: color 200ms ease-in-out;
}

.btn-big {
	display: inline-block;
	box-sizing: border-box;
	line-height: 1;
	padding: 14px 48px 15px;
	height: 40px;
	font-size: 14px;
	font-family: Roboto, sans-serif, Arial, Verdana;
	font-weight: 700;
	color: #ffffff;
	background-color: #38394b;
	border: 0;
	border-radius: 3px;
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: none;
	transition: color 200ms ease-in-out;
}

.btn-big:hover {
	opacity: 0.8;
	transition: opacity 200ms ease-in-out;
}

.btn-green {
	color: #ffffff;
	background-color: #52bc75;
}

.loading-big {
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding-top: 20%;
	text-align: center;
}

.loading-big img {
	width: 100px;

}

.page .cons-apply-lnk {
	display: inline-block;
	padding: 6px 15px;
	font-size: 12px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-weight: 400;
	text-decoration: none;
	color: #ffffff;
	background-color: #38394b;
	border: 1px solid #38394b;
	border-radius: 2px;

}

.page .cons-apply-lnk:hover {
	color: #38394b;
	background-color: #ffffff;
}

#client-nav-full {
	display: block;
	list-style: none;
	width: 300px;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	border-top: 1px solid #8c9293;
	background-color: #ffffff;
	box-shadow: -2px 0 8px #E6E6E9;
	z-index: 1000;
}

.client-nav-head {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	height: 85px;
	padding-top: 25px;
	padding-left: 20px;
	padding-right: 30px;
	vertical-align: top;
	border-bottom: 1px solid #f5f5f7;
	text-align: right;
}

.client-nav-buttons {
	display: block;
	width: 100%;
	padding-top: 14px;
	padding-bottom: 14px;
	list-style: none inside;
}

.client-nav-buttons li {
	display: block;
	width: 100%;
}

.client-nav-buttons li.client-nav-last {
	padding-top: 19px;
	border-top: 1px solid #f5f5f7;
}

.client-nav-buttons li a,
.client-nav-buttons li button {
	display: block;
	width: 100%;
	line-height: 1;
	padding: 13px 19px 12px;
	border: 0;
	border-left: 2px solid #ffffff;
	background: none;
	white-space: nowrap;
	font-size: 14px;
	text-transform: uppercase;
	text-decoration: none;
	text-align: left;
	color: #8d9294;
	cursor: pointer;
}

.client-nav-buttons li a:hover,
.client-nav-buttons li button:hover {
	border-left: 2px solid #38394b;
	background-color: #f6f6f8;
}

/* ##### FIN TABLE ###### */
.fin-table-hl {
	display: block;
	width: 100%;
	padding-top: 50px;
}

.fin-table {
	display: block;
	width: 100%;
	min-width: 300px;
	border: 1px solid #e8e8ea;
	background-color: #ffffff;
}

.fin-table-head {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding: 10px;
	line-height: 1;
}

.fin-table-head-left {
	display: inline-block;
	box-sizing: border-box;
	max-width: 49%;
	line-height: 1;
	padding-left: 3px;
	flex: 1 1 49%;
	text-align: left;
}

.fin-table-head-left p {
	line-height: 1;
	font-size: 15px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-weight: 400;
	color: #38394b;
}

.fin-table-head-right {
	display: inline-block;
	box-sizing: border-box;
	max-width: 49%;
	line-height: 1;
	padding-left: 10px;
	flex: 2 1 49%;
	text-align: right;
}

.fin-table-head-right .search {
	width: 70%;
	min-width: 90px;
	box-sizing: border-box;
	line-height: 1;
	height: 28px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	color: #38394b;
	font-size: 13px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	background: #fcfcfd url("/assets/ic/search.png") no-repeat 95% center;
	background-size: 10px auto;
	border: 1px solid #d8d8da;
}

.fin-table-body {
	display: block;
	width: 100%;
}

.fin-table-body table {
	width: 100%;
	border-collapse: collapse;
}

.fin-table-body table thead tr th {
	line-height: 1;
	padding: 9px;
	text-align: left;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-size: 11px;
	font-weight: normal;
	color: #8c9293;
	border-top: 1px solid #f5f5f7;
	white-space: nowrap;
}

.fin-table-body table thead tr th:last-child {
	text-align: right;
}

.fin-table-body table thead tr th .fin-order-btn {
	padding-right: 12px;
	border: 0;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-size: 11px;
	color: #8c9293;
	cursor: pointer;
	background: #fcfcfd url("/assets/ic/filter-up-down.png") no-repeat 95% center;
	background-size: auto 8px;
}

.fin-table-body table thead tr th .fin-order-btn:hover {
	background: #fcfcfd url("/assets/ic/filter-up-down-active.png") no-repeat 95% center;
	background-size: auto 8px;
}

.fin-table-body table thead tr th .fin-order-btn.asc {
	background: #fcfcfd url("/assets/ic/filter-up-down-asc.png") no-repeat 95% center;
	background-size: auto 8px;
}

.fin-table-body table thead tr th .fin-order-btn.desc {
	background: #fcfcfd url("/assets/ic/filter-up-down-desc.png") no-repeat 95% center;
	background-size: auto 8px;
}

.fin-table-body table tbody {
	display: block;
	width: calc(100% + 1px);
	max-height: 350px;
	overflow-y: auto;
	overflow-x: hidden;
}

.fin-table-body table thead,
.fin-table-body table tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.fin-table-body table thead {
	width: calc(100% - 2px)
}

.fin-table-body table tbody tr td {
	line-height: 1;
	padding: 10px 9px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-size: 13px;
	color: #38394b;
	border-top: 1px solid #f5f5f7;
}

.fin-table-body table tbody tr:nth-child(odd) td {
	background-color: #fcfcfd;
}

.fin-table-body table tbody tr td:last-child {
	text-align: right;
}

.fin-table-body table tbody tr td .coin-ico {
	max-width: 14px;
	height: 11px;
}

/* END OF COMPONENTS */

#root {
	display: block;
	width: 100%;
	height: 100%;
}

/** CONTENT **/

section.wrapper {
	display: inline-block;
	/*width: 84%;*/
	width: 100%;
	min-height: 100%;
	position: relative;
}

header {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 84px;
	box-sizing: border-box;
	padding: 26px 30px;
	position: relative;
	bottom: 0;
}

.header-logo-area {
	display: inline-block;
	flex: 1 0 168px;
}

.header-logo-area a {
	display: block;
	min-width: 105px;
	transition: opacity 200ms ease-in-out;
}

.header-logo-area a img {
	display: block;
	width: 60px;
}

.header-logo-area a:hover {
	opacity: 0.8;
	transition: opacity 200ms ease-in-out;
}

.header-cu-rates {
	display: inline-block;
	flex: 2 1 90%;
	position: relative;
}

.header-client-signedout,
.header-client-signedin {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	flex: 3 1 160px;
	height: 32px;
	position: relative;
	white-space: nowrap;
}

.header-client-deposits {
	display: inline-block;
	width: 150px;
	height: 32px;
	padding-right: 20px;
	border-right: 1px solid #e8e8ea;
	text-align: right;
	font-size: 14px;
}

.header-client-deposits h3 {
	display: block;
	width: 100%;
	line-height: 1;
	padding-bottom: 6px;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	color: #38394b;
}

.header-client-deposits p {
	display: block;
	width: 100%;
	line-height: 1;
	font-size: 12px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	color: #8c9293;
}

.header-client-deposits p em {
	padding-left: 7px;
	color: #52bc75;
	font-style: normal;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}

.header-client-to-profile {
	display: inline-block;
	width: 32px;
	height: 32px;
	line-height: 32px;
	margin-left: 20px;
	text-align: center;
	border-radius: 16px;
	color: #ffffff;
	background-color: #38394b;
	font-size: 13px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	text-decoration: none;
	transition: all 100ms ease-in-out;
}

.header-client-to-profile:hover {
	opacity: 0.8;
	transition: all 100ms ease-in-out;
}

.header-client-signedin .btn-menu {
	margin-left: 20px;
}

.header-client-signedin .btn-menu-close {
	margin-left: 5px;
}

.content {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding-left: 30px;
	padding-right: 30px;
	min-height: 100%;
	margin-bottom: 48px;
	position: relative;
}

.page {
	display: block;
	width: 100%;
	height: 100%;
}

/* HOMEPAGE */
.hp-wrapper {
	width: 100%;
	padding-top: 1%;
	padding-bottom: 4%;
	text-align: center;
}

.hp-buttons {
	display: block;
	width: 100%;
	min-width: 210px;
	padding-top: 3%;
	padding-bottom: 3%;
}

.hp-buttons .btn-big {
	width: 205px;
	padding: 14px 20px 15px;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 10px;
}
.hp-buttons .btn-big span{
	display: inline-block;
	width: 100%;
}

.hp-coin-list-hl {
	display: block;
	width: 960px;
	height: 446px;
	margin: 0 auto;
	border: 1px solid #e8e8ea;
	background-color: #ffffff;
}

/* LOGIN PAGE */
.sp-form-wrapper {
	width: 100%;
	padding-top: 2.2%;
	padding-bottom: 4%;
	text-align: center;
}

.sp-form-hl {
	display: inline-block;
	min-width: 400px;
	min-height: 100px;
	margin: 0 auto;
	background-color: #ffffff;
	border: 1px solid #e8e8ea;
	box-shadow: 1px 2px 2px #f0f0f2;
}

.sp-form-check-domain {
	display: block;
	width: inherit;
	padding: 40px;
	border-bottom: 1px solid #e8e8ea;
}

.sp-form-check-domain {
	display: block;
	width: inherit;
	padding: 40px;
	border-bottom: 1px solid #e8e8ea;
}

.sp-form-check-domain p {
	display: block;
	width: inherit;
	padding-bottom: 6px;
	line-height: 1.3;
	font-size: 13px;
	color: #929999;
}

.sp-form-check-domain p span {
	color: #52bc75;
}

.sp-form-check-domain input {
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #8d9294;
	padding-left: 39px;
}

.sp-form-check-domain input.ok {
	border: 1px solid #8ed3a5;
	background: transparent url("/assets/ic/locker-ok.png") no-repeat 0 0;
	background-size: auto 33px;
}

.sp-form-check-domain input.nok {
	border: 1px solid #f39c90;
	background: transparent url("/assets/ic/locker-nok.png") no-repeat 0 0;
	background-size: auto 33px;
}

.page-login .sp-form-hl form {
	width: 400px
}

.sp-form-hl form {
	display: block;
	box-sizing: border-box;
	padding: 17px 40px 30px;
}

.sp-form-hl form .sp-form-row {
	display: block;
	width: 100%;
	padding-bottom: 12px;
}

.sp-form-hl form .sp-form-group {
	display: block;
}

.sp-form-hl .sp-form-err {
	display: block;
	width: 80%;
	min-height: 15px;
	padding: 0 10% 7px;
	font-size: 12px;
	color: #EC6653;
	text-align: center;
}

.sp-form-hl .sp-form-err.has-errors {
	line-height: 1.3;
}

.sp-form-alternatives {
	display: block;
	padding-top: 2%;
	padding-bottom: 2%;
	text-align: center;
	font-size: 13px;
	color: #929999;
}

.sp-form-alternatives a {
	color: #38394b;
	text-decoration: underline;
	cursor: pointer;
	font-weight: 500;
}

.sp-form-alternatives a:hover {
	text-decoration: none;
}

.sp-lost-pass-lnk {
	display: block;
	width: 100%;
	font-size: 11px;
	color: #38394b;
	text-decoration: underline;
}

.sp-lost-pass-lnk:hover {
	text-decoration: none;
}

/* ######### GA LOGIN ########## */
.sp-form-ga-login{
	padding:40px;
	padding-top:10px;
}
.sp-form-ga-login input{
	box-sizing: border-box;
	width: 100%;
	padding: 5px 8px 4px 8px;
	text-align: center;
	font-size: 25px;
}
.sp-form-ga-login input.ok{
	border: 1px solid #8ed3a5;
	background: transparent url(/assets/ic/ic-key-ok.png) no-repeat 0 0;
	background-size: auto 38px;
}
.sp-form-ga-login input.nok{
	border: 1px solid #f39c90;
	background: transparent url(/assets/ic/ic-key-nok.png) no-repeat 0 0;
	background-size: auto 38px;
}
.ga-login-buttons-area{
	width: 441px !important;
	box-sizing: border-box;
	border-top: 1px solid #e8e8ea;
	padding: 36px 40px 40px !important;
	text-align: center;
}
.ga-login-buttons-area button{
	border-radius: 2px;
	background-color: #52BC75;
}


.sp-form-registration-title {
	display: block;
	box-sizing: border-box;
	width: 100%;
	line-height: 1;
	padding-top: 30px;
	padding-bottom: 0;
	font-size: 17px;
	color: #52bc75;
}

.page-register .sp-form-hl form {
	width: 400px;
	padding-top: 9px;
}

.sp-form-inp-note {
	display: block;
	width: inherit;
	line-height: 1.4;
	padding-top: 9px;
	padding-bottom: 15px;
	color: #8c9293;
	font-size: 11px;
	vertical-align: top;
	text-align: left;
}

.sp-form-inp-note i {
	float: left;
	position: relative;
	top: 2px;
}

.sp-form-check-group {
	display: inline-block;
	width: inherit;
	padding-bottom: 7px;
	text-align: left;
	font-size: 11px;
	color: #8c9293;
	position: relative;
	top: -5px;
}

.sp-form-check-group input {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	margin-right: 6px;
	cursor: pointer;
	position: relative;
	top: 3px;
}

.sp-form-check-group a {
	display: inline;
	font-size: 11px;
	color: #38394b;
}

/* TEXT PAGE */

.page-text-only .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
	font-size: 13px;
}

.page-text-only .content ul,
.page-text-only .content ol {
	list-style: none inside;
	padding-bottom: 9px;
}

.page-text-only .content h1 {
	padding-bottom: 9px;
}

.page-text-only .content p {
	padding-bottom: 9px;
}

.page-text-only .content div {
	padding-bottom: 9px;
}

/* MARKETS TABLE */

.markets-large {
	display: block;
	width: 100%;
	height: 446px;
}

.markets-filters-all {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	min-height: 48px;
}

.markets-filter-main-markets {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: left;
	align-items: flex-start;
	box-sizing: border-box;
	flex: 1 1 50%;
	padding-left: 15px;
	padding-right: 15px;
	text-align: left;
}

.markets-filter-main-markets button {
	display: inline-block;
	width: 48px;
	line-height: 14px;
	padding-top: 19px;
	padding-bottom: 13px;
	color: #8d9294;
	background: none;
	font-size: 13px;
	font-weight: 700;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	text-transform: uppercase;
	border: 0;
	border-bottom: 2px solid #ffffff;
	cursor: pointer;
	transition: all 100ms ease-in-out;
}

.markets-filter-main-markets button:first-child {
	width: 35px;
}

.markets-filter-main-markets:hover button.active {
	color: #8d9294;
	border-bottom: 2px solid #ffffff;
}

.markets-filter-main-markets button:hover,
.markets-filter-main-markets button.active {
	color: #38394b;
	border-bottom: 2px solid #38394b;
	transition: all 100ms ease-in-out;
}

.markets-filter-main-markets:hover button.active:hover {
	color: #38394b;
	border-bottom: 2px solid #38394b;
	transition: all 100ms ease-in-out;
}

.markets-filter-main-search {
	display: inline-block;
	flex: 2 1 50%;
	padding-top: 10px;
	padding-left: 15px;
	padding-right: 10px;
	text-align: right;
}

.markets-filter-main-search input {
	width: 220px;
	box-sizing: border-box;
	line-height: 1;
	height: 28px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	color: #38394b;
	font-size: 13px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	background: #fcfcfd url("/assets/ic/search.png") no-repeat 95% center;
	background-size: 10px auto;
	border: 1px solid #d8d8da;
}

.markets-filter-main-table {
	display: block;
}

.markets-filter-main-table div.griddle-loadingResults {
	padding-top: 40px;
	background: transparent url("/assets/ic/loading.gif") no-repeat center top;
	background-size: 30px auto;
	font-size: 0;
	text-align: center;
}

.markets-filter-main-table div.griddle-noResults {
	width: 100%;
	padding-top: 20px;
	font-size: 13px;
	text-align: center;
}

.markets-filter-main-table table {
	width: 100%;
	border-collapse: collapse;
	border: 0;
	border-top: 1px solid #f5f5f7;
}

.markets-filter-main-table table thead {
	display: block;
	width: 100%;
	border: 0;
	border-bottom: 1px solid #e8e8ea;
}

.markets-filter-main-table table thead tr th {
	box-sizing: border-box;
	line-height: 1;
	padding-top: 9px;
	padding-left: 15px;
	padding-bottom: 7px;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #8d9294;
	text-align: left;
}

.markets-filter-main-table table thead tr th:hover {
	color: #38394b;
}

.markets-filter-main-table table thead tr th span.griddle-heading-ascending,
.markets-filter-main-table table thead tr th span.griddle-heading-descending {
	padding-left: 5px;
	color: #38394b;
	font-size: 7px;
}

.markets-filter-main-table table tbody {
	display: block;
	width: 100%;
	height: calc(100% - 127px);
	overflow-y: auto;
	overflow-x: hidden;
}

.hp-coin-list-hl .markets-filter-main-table table tbody {
	height: 371px;
}

.markets-filter-main-table table tbody tr td {
	box-sizing: border-box;
	padding-top: 10px;
	padding-left: 15px;
	padding-bottom: 9px;
	text-align: left;
	border-bottom: 1px solid #f5f5f7;
	color: #38394b;
	font-size: 13px;
	font-weight: 400;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	cursor: pointer;
}

.markets-filter-main-table table tbody tr:hover td,
.markets-filter-main-table table tbody tr:hover td span,
.markets-filter-main-table table tbody tr:hover td span strong {
	color: #c3c3c3 !important;
}

.markets-filter-main-table table tbody tr td span.exch-nm-ic {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: left;
	align-items: flex-start;
	color: #8d9294;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
}

.markets-filter-main-table table tbody tr td span.exch-nm-ic img {
	width: 16px;
	margin-right: 8px;
}

.markets-filter-main-table table tbody tr td span.exch-nm-ic strong {
	color: #38394b;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
}

.markets-filter-main-table table tbody tr td span.cu-price em {
	padding-left: 5px;
	font-style: normal;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	text-align: right;
}

.markets-filter-main-table table tbody tr td span.cu-price em.up {
	color: #52bc75;
}

.markets-filter-main-table table tbody tr td span.cu-price em.down {
	color: #ed6754;
}

.markets-large table thead tr th:nth-child(1) {
	width: 146px;
}

.markets-large table thead tr th:nth-child(2) {
	width: 135px;
}

.markets-large table thead tr th:nth-child(3) {
	width: 192px;
}

.markets-large table thead tr th:nth-child(4) {
	width: 153px;
}

.markets-large table thead tr th:nth-child(5) {
	width: 175px;
}

.markets-large table tbody tr:nth-child(odd) td {
	background-color: #fcfcfd;
}

.markets-large table tbody tr td:nth-child(1) {
	width: 151px;
}

.markets-large table tbody tr td:nth-child(2) {
	width: 145px;
}

.markets-large table tbody tr td:nth-child(3) {
	width: 200px;
}

.markets-large table tbody tr td:nth-child(4) {
	width: 155px;
}

.markets-large table tbody tr td:nth-child(5) {
	width: 181px;
}

.markets-large table tbody tr td:nth-child(6) {
	width: 110px;
}

/* EXCHANGE */

aside {
	display: inline-block;
	width: 320px;
	height: 100%;
	vertical-align: top;
	background-color: #ffffff;
}

html body div#root aside.hidden {
	display: inline-block;
}

.aside-arrow {
	display: none;
	width: 100%;
	height: 14px;
	color: #FFF;
	text-align: center;
}

.aside-logo {
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 84px;
	padding-top: 33px;
	padding-left: 20px;
	border-bottom: 1px solid #f4f4f6;
	background-color: #FFF;
}

.aside-logo a {
	display: block;
	cursor: pointer;
	line-height: 1;
	text-decoration: none;
}

.aside-logo a img {
	width: 60px;
	height: auto;
}

.aside-logo a:hover img {
	opacity: 0.8;
}

.page-exchange section.wrapper {
	width: calc(100% - 320px);
}

.page-exchange section.wrapper .header-logo-area {
	display: none;
}

.aside-exchange {
	display: block;
	width: 100%;
	height: calc(100% - 84px);
	overflow: hidden;
}

.aside-exchange div {
	display: block;
}

.aside-exchange #markets-aside-hldiv {
	display: block;
	height: 100%;
}

.aside-exchange .markets-filters-hl {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 10px 10px 0;
}

.aside-exchange .markets-filters-hl .markets-filters-all {
	display: block;
}

.aside-exchange .markets-filters-hl .markets-filters-all .markets-filter-main-search,
.aside-exchange .markets-filters-hl .markets-filters-all .markets-filter-main-markets {
	display: block;
	padding: 0;
	flex: none;
}

.aside-exchange .markets-filters-hl .markets-filters-all .markets-filter-main-search {
	padding-bottom: 10px;
	border-bottom: 1px solid #f4f4f6;
}

.aside-exchange .markets-filters-hl .markets-filters-all .markets-filter-main-search input {
	width: 100%;
	box-sizing: border-box;
}

.aside-exchange .markets-filter-main-markets button {
	padding-bottom: 17px;
}

.aside-exchange .markets-filter-main-table {
	height: 100%;
}

.aside-exchange .markets-filter-main-table table {
	height: 100%;
}

.aside-exchange .markets-filter-main-table table tr th:nth-child(1) {
	width: 100px;
	padding-left: 10px;
}

.aside-exchange .markets-filter-main-table table tr th:nth-child(2) {
	width: 87px;
}

.aside-exchange .markets-filter-main-table table tr th:nth-child(3) {
	width: 108px;
	text-align: right;
}

.aside-exchange .markets-filter-main-table table tr td {
	padding-top: 8px;
	padding-left: 10px;
	padding-bottom: 7px;
	vertical-align: top;
}

.markets-filter-main-table .exch-pair-nm {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: left;
	align-items: flex-start;
	box-sizing: border-box;
	width: 95px;
	height: 34px;
	overflow: hidden;
}

.markets-filter-main-table .add2fav {
	display: inline-block;
	flex: 1 1 23px;
	width: 23px;
	height: 32px;
	border: 0;
	box-sizing: border-box;
	background: transparent url("/assets/ic/unfav.png") no-repeat left center;
	background-size: 13px auto;
	cursor: pointer;
}

.markets-filter-main-table .add2fav.selected {
	background: transparent url("/assets/ic/fav.png") no-repeat left center;
	background-size: 13px auto;
}

.markets-filter-main-table .exch-pairs {
	display: inline-block;
	flex: 2 1 105px;
	width: 13px;
	height: 13px;
}

.markets-filter-main-table .exch-pairs em {
	display: block;
	width: 100%;
	height: 15px;
	padding-bottom: 5px;
	font-style: normal;
	font-size: 13px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #38394b;
}

.markets-filter-main-table .exch-pairs small {
	display: block;
	width: 100%;
	font-style: normal;
	font-size: 10px;
	color: #38394b;
}

.markets-filter-main-table .exch-volumes {
	display: block;
	width: 75px;
}

.markets-filter-main-table .exch-volumes span {
	display: block;
	width: 100%;
	line-height: 1.22;
	padding-bottom: 1px;
}

.aside-exchange .markets-filter-main-table table tr td:nth-child(2) {
	max-width: 110px;
	padding-top: 9px;
	font-size: 12px;
}

.aside-exchange .markets-filter-main-table table tr td:nth-child(3) {
	max-width: 110px;
	padding-top: 10px;
	padding-right: 5px;
	font-size: 12px;
	text-align: right;
}

.aside-exchange .markets-filter-main-table table tr td .cu-price {
	display: block;
	line-height: 15px;
	font-size: 12px;
	text-align: right;
}

.aside-exchange .markets-filter-main-table table tr td .cu-price em {
	display: block;
	line-height: 1;
	padding-top: 5px;
	padding-left: 0;
	font-size: 11px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	width: 100%;
	text-align: right;
}

.exhange-wrapper {
	display: block;
	padding-bottom: 30px;
}

.exchain-head-info {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: 32px;
	padding-top: 26px;
	padding-bottom: 26px;
	overflow: hidden;
}

.exchain-head-info-name {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	padding-right: 30px;
	border-right: 1px solid #e8e8ea;
}

.exchain-head-info-name img {
	display: inline-block;
	width: 33px;
	flex: 1 1 33px;
	position: relative;
	top: 1px;
}

.exchain-head-info-name .ehi-name {
	display: inline-block;
	flex: 2 1 50%;
	line-height: 1;
}

.exchain-head-info-name h1 {
	display: block;
	min-width: 75px;
	line-height: 1;
	padding-left: 10px;
	color: #38394b;
	font-size: 16px;
	font-weight: 700;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	white-space: nowrap;
}

.exchain-head-info-name h1 small {
	display: block;
	width: 100%;
	line-height: 1;
	padding-top: 7px;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #8e9395;
}

.exchain-head-info-name .ehi-tgl-aside {
	display: none;
	width: 10px;
	padding-left: 10px;
	padding-top: 0;
	line-height: 1;
}

.exchain-head-info-name .ehi-tgl-aside button {
	display: block;
	width: 100%;
	line-height: 1.1;
	font-size: 14px;
	font-weight: bold;
	color: #38394b;
	text-decoration: none;
	border: 0;
	background: none;
	cursor: pointer;
}

.exchain-head-info-name .ehi-tgl-aside a:hover {
	color: #000000;
}

.exchain-head-info-prices {
	display: inline-block;
	padding-right: 30px;
	padding-left: 10px;
	border-right: 1px solid #e8e8ea;
}

.exchain-head-info-prices .ehi-top {
	display: block;
	height: 16px;
	line-height: 1;
	color: #38394b;
	font-size: 15px;
	font-weight: 700;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
}

.exchain-head-info-prices .ehi-top .ehi-change {
	display: inline-block;
	line-height: 1;
	padding-left: 10px;
	text-align: right;
	font-size: 14px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
}

.exchain-head-info-prices .ehi-top .ehi-change.up {
	color: #52bc75;
}

.exchain-head-info-prices .ehi-top .ehi-change.up:before {
	content: "+";
}

.exchain-head-info-prices .ehi-top .ehi-change.down {
	color: #ed6754;
}

.exchain-head-info-prices .ehi-top .ehi-change.down:before {
	content: "-";
}

.exchain-head-info-prices .ehi-top .ehi-change:after {
	content: "%";
}

.odometer {
	position: relative;
	top: -1px;
}

.odometer-negation-mark {
	display: none !important;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
	line-height: 14px !important;
	font-family: 'Roboto', sans-serif, Arial !important;
	font-size: 14px !important;
}

.odometer-formatting-mark,
.odometer-radix-mark {
	line-height: 14px !important;
	font-family: 'Roboto', sans-serif, Arial !important;
	font-size: 14px !important;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
	line-height: 14px !important;
	font-family: 'Roboto', sans-serif, Arial !important;
	font-size: 14px !important;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
	line-height: 14px !important;
	font-family: 'Roboto', sans-serif, Arial !important;
	font-size: 14px !important;
}

.exchain-head-info-prices .ehi-bottom {
	display: block;
	width: 100%;
	line-height: 1;
	padding-top: 8px;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #8e9395;
}

.exchain-head-info-prices:last-child {
	border: 0;
}

.TVChartContainer {
	height: 364px;
}

.hover-blk {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.15);
}

.exchange-line-chart-form,
.exchange-line-trades {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
}

.exchange-line-chart-form {
	margin-bottom: 10px;
}

.exchange-line-chart-form .exchange-chart-area,
.exchange-line-trades .exchange-trades {
	display: inline-block;
	width: 57%;
	max-width: 57%;
	flex: 1 1 57%;
	min-height: 350px;
	margin-right: 5px;
	background-color: #FFF;
	border: 1px solid #e8e8ea;
	box-shadow: 0 3px 0 #f0f0f2;
}

.exchange-line-chart-form .exchange-forms-area,
.exchange-line-trades .exchange-buy-orders {
	display: inline-block;
	flex: 2 1 43%;
	min-height: 350px;
	margin-left: 5px;
	background-color: #FFF;
	border: 1px solid #e8e8ea;
	box-shadow: 0 3px 0 #f0f0f2;
}

.exchange-line-trades .exchange-trades {
	height: 350px;
}

.exchange-line-trades .exchange-buy-orders {
	height: 350px;
}

.exchange-area-head {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	min-height: 42px;
	padding-top: 13px;
	border-bottom: 1px solid #e8e8ea;
	vertical-align: top;
}

.exchange-area-head-left {
	display: inline-block;
	flex: 1 1 25%;
	white-space: nowrap;
	padding-left: 13px;
	overflow: hidden;
	vertical-align: top;
}

.exchange-area-head-center {
	display: inline-block;
	flex: 2 1 50%;
	padding-left: 10px;
	padding-right: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-align: center;
}

.exchange-area-head-right {
	display: inline-block;
	flex: 2 1 25%;
	white-space: nowrap;
	padding-right: 13px;
	overflow: hidden;
	text-align: right;
	vertical-align: top;
}

.exchange-area-head-left img,
.exchange-area-head-right img {
	display: inline-block;
	width: 16px;
	float: left;
}

.exchange-area-head-left span,
.exchange-area-head-right span {
	display: inline-block;
	line-height: 1.1;
	padding-left: 9px;
	font-size: 13px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #38394b;
	white-space: nowrap;
	float: left;
}

.exchange-forms-area .exchange-area-head-left span {
	line-height: 1.3;
}

.exchange-area-head-right span {
	padding-right: 10px;
	float: right;
}

.exchange-area-head-right img {
	float: right;
}

.exchange-area-head-center ul.exchange-head-nav {
	list-style: none inside;
	line-height: 11px;
	padding-top: 2px;
}

.exchange-area-head-center ul.exchange-head-nav li {
	display: inline-block;
	line-height: 11px;
}

.exchange-area-head-center ul.exchange-head-nav li button {
	display: inline-block;
	line-height: 1;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 12px;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #8e9395;
	border: 0;
	border-bottom: 2px solid #ffffff;
	background: none;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 100ms ease-in-out;
}

.exchange-area-head-center ul.exchange-head-nav li button.active,
.exchange-area-head-center ul.exchange-head-nav li button:hover {
	color: #38394B;
	border-bottom: 2px solid #38394b;
	transition: all 100ms ease-in-out;
}

.exchange-area-buy-sell-diff {
	line-height: 1;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #ed6754;
}

.exhange-area-body{
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	min-height: 100px;
	padding: 35px;
	position: relative;
	z-index: 0;
}
.exhange-area-body-left,
.exhange-area-body-right{
	display: inline-block;
	box-sizing: border-box;
	width: 50%;
	min-height: 238px;
}
.exhange-area-body-left{
	flex:1 1 50%;
	padding-right: 17px;
}
.exhange-area-body-right{
	flex:2 1 50%;
	padding-left: 17px;
}
.exhange-area-body-left h3,
.exhange-area-body-right h3{
	width: 100%;
	line-height: 1;
	padding-bottom:15px;
	font-size: 15px;
	color:#38394b;
	white-space: nowrap;
	clear: both;
}
.exhange-area-body-left h3 small,
.exhange-area-body-right h3 small{
	padding-top:1px;
	font-size: 12px;
	font-weight: normal;
	color:#929999;
	white-space: nowrap;
	float: right;
}
.exhange-area-body-left h3 small span,
.exhange-area-body-right h3 small span{
	font-size: 13px;
	font-weight: normal;
	color:#38394b;
}

.trade-inputs{
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	height: 29px;
	margin-bottom: 7px;
	overflow: hidden;
}
.trade-inputs label.tr-inp-pre{
	display: inline-block;
	box-sizing: border-box;
	width:30%;
	line-height: 27px;
	padding-left:9px;
	color:#8e9395;
	border:1px solid #f4f4f6;
	background-color: #F8F8FA;
	font-size: 12px;
	font-weight: normal;
	white-space: nowrap;
}
.trade-inputs input{
	display: inline-block;
	box-sizing: border-box;
	width:50%;
	height: 28px;
	line-height: 26px;
	padding-left:9px;
	color:#38394b;
	border:1px solid #d8d8da;
	border-right: 0;
	background: none #FFFFFF !important;
	font-size:12px;
}
.trade-inputs span.tr-inp-post{
	display: inline-block;
	box-sizing: border-box;
	width:20%;
	height: 28px;
	line-height: 25px;
	padding-right: 9px;
	color:#8D9294;
	border:1px solid #d8d8da;
	border-left: 0;
	background-color: #FFF;
	font-size:12px;
	text-align: right;
	text-transform: uppercase;
}
.trade-inputs-slider{
	width: 100%;
	box-sizing: border-box;
	height: 25px;
	margin-top: 16px;
	padding-left: 33%;
	padding-right: 6%;
}

.trade-button{
	display: block;
	width: 100%;
	padding-top:26px;
}

.trade-button .trade-login-or-reg{
	display: block;
	width: 100%;
	text-align: center;
	font-size:11px;
}
.trade-button .trade-login-or-reg a{
	font-size:11px;
	text-decoration: underline;
	color:#38394b;
}

.trade-button button.trade-submit{
	display: block;
	width: 100%;
	height: 40px;
	color:#FFF;
	cursor: pointer;
	font-size:13px;
	font-weight: bold;
	border:0;
	border-radius: 2px;
	opacity: 1;
	transition: opacity 0.3s, visibility 0.3s;
}
.trade-button button#trade-btn-buy{
	background-color: #52bc75;
}
.trade-button button#trade-btn-sell{
	background-color: #ed6754;
}
.trade-button button.trade-submit:hover{
	opacity:0.8;
	transition: opacity 0.3s, visibility 0.3s;
}

.trademode-0 form div.trade-inputs input#buyprice,
.trademode-0 form div.trade-inputs input#sellprice{
	color:#CCC;
	background-color: #F8F8FA !important;
	cursor: not-allowed;
}

.trademode-0 form .trade-limit-inp-area,
.trademode-1 form .trade-limit-inp-area{
	display: none;
}

.trade-err-msg{
	display: block;
	width:100%;
	padding-top:10px;
	font-size:12px;
	font-style: italic;
	font-weight: bold;
	color:#ed6754;
	text-align: center;
}

#trade-post-order-blk{
	display: block;
	box-sizing: border-box;
	width: 39%;
	height: 340px;
	padding-top:50px;
	background: rgba(255,255,255,0.9);
	box-shadow: 0 0 7px #CCC;
	position: absolute;
	margin-left: 10px;
	top: 10px;
	z-index: 1000;
	text-align: center;
}
#trade-post-order-blk h2{
	display: block;
	width:100%;
	line-height: 1.3;
	padding-bottom:20px;
	font-size: 20px;
}
#trade-post-order-blk h3{
	display: block;
	width:100%;
	line-height: 1.3;
	padding-bottom:10px;
	font-size: 14px;
}
#trade-post-order-blk.success h2,
#trade-post-order-blk.success h3{
	color:#52bc75;
}
#trade-post-order-blk.error h2,
#trade-post-order-blk.error h3{
	color:#ed6754;
}

.trades-area-body{
	display: block;
	width:100.1%;
	height: 307px;
	overflow: hidden;
}

.trades-area-body-list{
	display: block;
	box-sizing: border-box;
	width:100%;
	height: 279px;
	overflow: hidden;
	overflow-y: auto;
	min-height: 240px;
}
.trades-area-body-list ul{
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: stretch;
	list-style: none inside;
	width: 100%;
}
.trades-area-body-list ul li{
	display: inline-block;
}
.trades-area-body-list .trade-line:nth-child(2n+1){
	background-color: #fafafc;
}
.trade-history-head{
	display: block;
	list-style: none inside;
	background-color: #FFF;
	border-bottom: 1px solid #e8e8ea;
	box-shadow: 0 1px 1px #F3F3F3;
}
.trade-history-head li{
	display: inline-block;
	box-sizing: border-box;
	height: 27px;
	line-height: 28px;
	font-size:10px;
	color:#8e9395;
	text-align: left;
	white-space: nowrap;
}
.trade-history-head li:first-child{
	width: 18.6%;
	max-width: 18.6%;
	padding-left:2%;
}
.trade-history-head li:nth-child(2) {
	width: 19.5%;
	max-width: 20%;
}
.trade-history-head li:nth-child(3) {
	width: 24.5%;
	max-width: 25%;
}
.trade-history-head li:nth-child(4) {
	width: 24.5%;
	max-width: 25%;
}
.trade-history-head li:nth-child(5) {
	width: auto;
}

ul.trade-line li{
	height: 27px;
	line-height: 28px;
	font-size:11px;
	color:#38394b;
	border-bottom: 1px solid #f6f6f8;
	text-align: left;
}
ul.trade-line li button{
	border:0;
	background: none;
	cursor: pointer;
	font-size:11px;
}
ul.trade-line li:first-child{
	width: 18%;
	max-width: 18%;
	height: 19px;
	padding-left:1%;
	padding-top:8px;
	clear:both;
}
ul.trade-line li:first-child span{
	display: block;
	box-sizing: border-box;
	padding-left:6px;
	height: 12px;
	line-height: 12px;
	border-left: 2px solid rgba(255,255,255,0);
}
ul.trade-line li:nth-child(2){
	width: 20%;
	max-width: 20%;
}
ul.trade-line li:nth-child(3){
	width: 25%;
	max-width: 25%;
}
ul.trade-line li:nth-child(4){
	width: 25%;
	max-width: 25%;
}
ul.trade-line li:nth-child(5){
	width: 10%;
}

.trades-area-body-list ul.same-client-1 li {
	font-weight: bold;
}

.trades-area-body-list ul.same-client-1 li:first-child span{
	display:inline-block;
	padding-left: 5px;
	border-left: 2px solid #38394B;
	font-weight: bold;
	float:left;
}


.trades-area-body ul.trade-type-0 li:nth-child(2),
.trades-area-body ul.trade-type-0 li:nth-child(3){
	color:#52bc75;
}

.trades-area-body ul.trade-type-1 li:nth-child(2),
.trades-area-body ul.trade-type-1 li:nth-child(3){
	color:#ed6754;
}

.trade-line-note li{
	width:100%;
	padding-top:100px;
	text-align: center;
	font-size:11px;
	color:#38394b;
}
.trade-line-note li a{
	font-size:11px;
	color:#38394b;
}

.orders-body-buy,
.orders-body-sell{
	display: inline-block;
	box-sizing: border-box;
	width:50%;
	height:307px;
	overflow: hidden;
	vertical-align: top;

}
.orders-body-buy{
	border-right: 1px solid #F5F5F7;
}

.orders-body-head{
	display: block;
	list-style: none inside;
	height: 27px;
	background-color: #FFF;
	border-bottom: 1px solid #e8e8ea;
	box-shadow: 0 1px 1px #F3F3F3;
	white-space: nowrap;
}
.orders-body-head li {
	display: inline-block;
	box-sizing: border-box;
	height: 27px;
	line-height: 28px;
	font-size: 10px;
	color: #8e9395;
	text-align: left;
	white-space: nowrap;
}
.orders-body-head li:first-child {
	width: 36%;
	max-width: 36%;
	padding-left: 4%;
}
.orders-body-head li:nth-child(2){
	width: 32%;
	max-width: 32%;
}
.orders-body-head li:nth-child(3){
	width: 28%;
	max-width: 28%;
	padding-right: 10%;
	text-align: right;
}
.orders-body-buy-list,
.orders-body-sell-list{
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 279px;
	overflow-x: hidden;
	overflow-y: visible;
	min-height: 240px;
}

.orders-body-buy-list ul,
.orders-body-sell-list ul{
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: stretch;
	list-style: none inside;
	width: 100%;
}

.orders-body-buy-list ul{
	background: url(/assets/ic/bg-line-buy.png) repeat-x 0 0;
	background-size: 0 0;
}
.orders-body-sell-list ul{
	background: url(/assets/ic/bg-line-sell.png) no-repeat 0 0;
	background-size: 0 0;
}

.orders-body-buy-list ul li,
.orders-body-sell-list ul li{
	display: inline-block;
	height: 27px;
	line-height: 28px;
	font-size: 11px;
	color: #38394b;
	border-bottom: 1px solid #f6f6f8;
	text-align: left;
	white-space: nowrap;
}
.orders-body-buy-list .order-line:nth-child(2n+1),
.orders-body-sell-list .order-line:nth-child(2n+1){
	background-color: #fafafc;
}

ul.order-line li:first-child{
	width: 32%;
	max-width: 32%;
	padding-left: 4%;
}
ul.order-line li:nth-child(2){
	width: 32%;
	max-width: 32%;
}
ul.order-line li:nth-child(3){
	width: 32%;
	max-width: 32%;
}

ul.order-line li button {
	border: 0;
	background: none;
	cursor: pointer;
	font-size: 11px;
}
.orders-body-buy-list ul.order-line li:nth-child(3) button{
	color: #52bc75;
}
.orders-body-sell-list ul.order-line li:nth-child(1) button{
	color: #ed6754;
}

/* ############## FEES ################# */

.page-fees .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

.page-fees .fin-table-body table tr th:nth-child(1) {
	width: 50px;
}

.page-fees .fin-table-body table tr td:nth-child(1) {
	width: 50px;
	white-space: nowrap;
	padding-right: 8px;
	max-width: 50px;
}

.page-fees .fin-table-body table tr td:nth-child(1) strong {
	display: inline-block;
	line-height: 1.1;
	padding-left: 7px;
	padding-right: 0;
}

.page-fees .fin-table-body table tr td:nth-child(2) {
	white-space: nowrap;
	text-align: left;
	color: #8d9294;
	line-height: 1;
	padding-left: 0;
	font-size: 13px;
}

.page-fees .fin-table-body table tr td:nth-child(2) span {
	color: #8d9294;
	line-height: 1;
	padding-left: 9px;
	border-left: 1px solid #f5f5f7;
	font-size: 13px;
}

/* ############## CONTENT PAGE ################# */

.page-cnt .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

.page-cnt .content h2 {
	display: block;
	width: 100%;
	padding-bottom: 25px;
}

.page-cnt .content h2 small {
	display: block;
	width: 100%;
	padding-top: 4px;
	padding-bottom: 4px;
	font-size: 12px;
	font-style: italic;
	color: #8d9294;
}

.page-cnt .content div.page-content{
	display: block;
	width: 100%;
	padding-bottom: 25px;
}


/* ############## NEWS ################# */

.page-news .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

.page-news .news-link {
	display: block;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 12px;
	border-bottom: 1px solid #8d9294;
	color: #38394b;
	text-decoration: none;
}

.page-news .news-link:hover {
	color: #8d9294;
}

.page-news .news-link small {
	display: block;
	width: 100%;
	padding-top: 4px;
	padding-bottom: 4px;
	font-size: 12px;
	font-style: italic;
	color: #8d9294;
}

.page-news-article .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

.page-news-article .content h1 {
	display: block;
	width: 100%;
	padding-bottom: 25px;
}

.page-news-article .content h1 small {
	display: block;
	width: 100%;
	padding-top: 4px;
	padding-bottom: 4px;
	font-size: 12px;
	font-style: italic;
	color: #8d9294;
}

.page-news-article .content div.article-content {
	display: block;
	width: 100%;
	padding-bottom: 25px;
}

.back-btn {
	display: block;
	color: #38394b;
	text-decoration: none;
}

.back-btn:hover {
	color: #000;
}

/* ############## COINS ################# */

.page-coins .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

.page-coins .fin-table-body table tr td {
	font-size: 12px;
}

.page-coins .fin-table-body table tr th:nth-child(1) {
	width: 50px;
}

.page-coins .fin-table-body table tr td:nth-child(1) {
	width: 50px;
	padding-right: 8px;
	white-space: nowrap;
	font-size: 12px;
}

.page-coins .fin-table-body table tr td:first-child strong {
	display: inline-block;
	line-height: 1.1;
	padding-left: 7px;
	padding-right: 0;
}

.page-coins .fin-table-body table tr td:nth-child(2) {
	line-height: 1;
	padding-left: 0;
	white-space: nowrap;
	text-align: left;
	color: #8d9294;
	font-size: 13px;
}

.page-coins .fin-table-body table tr td:nth-child(2) span {
	color: #8d9294;
	line-height: 1;
	padding-left: 9px;
	border-left: 1px solid #f5f5f7;
	font-size: 12px;
}

/* ############## WALLETS ################# */

.page-wallets .content {
	width: 70%;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

.page-wallets .fin-table-body table tr th:nth-child(1) {
	width: 49px;
}

.page-wallets .fin-table-body table tr td:nth-child(1) {
	width: 50px;
	white-space: nowrap;
	padding-right: 8px;
	max-width: 50px;
}

.page-wallets .fin-table-body table tr td:nth-child(1) strong {
	display: inline-block;
	line-height: 1.1;
	padding-left: 7px;
	padding-right: 0;
}

.page-wallets .fin-table-body table tr td:nth-child(2) {
	white-space: nowrap;
	text-align: left;
	color: #8d9294;
	line-height: 1;
	padding-left: 0;
	font-size: 13px;
}

.page-wallets .fin-table-body table tr td:nth-child(2) span {
	color: #8d9294;
	line-height: 1;
	padding-left: 9px;
	border-left: 1px solid #f5f5f7;
	font-size: 13px;
}

.page-wallets .fin-table-body table tr th:nth-child(4),
.page-wallets .fin-table-body table tr td:nth-child(4) {
	width: 300px;
}

.page-wallets .fin-table-body table tr th:nth-child(5),
.page-wallets .fin-table-body table tr td:nth-child(5) {
	width: 105px;
}


/* ############## OTHERS ################# */

.blk-tabbed-big{
	width: 803px;
	min-width: 400px;
	min-height: 100px;
	margin: 0 auto;
	background-color: #ffffff;
	border: 1px solid #e8e8ea;
	-webkit-box-shadow: 1px 2px 2px #f0f0f2;
	box-shadow: 1px 2px 2px #f0f0f2;
}

.blk-tabbed-big-tabs {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	border-bottom:1px solid #e8e8ea;
	text-align: center;
}

.blk-tabbed-big-tabs button {
	display: inline-block;
	line-height: 1;
	padding: 18px 15px 15px;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	color: #8e9395;
	border: 0;
	border-bottom: 2px solid #ffffff;
	background: none;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 100ms ease-in-out;
}

.blk-tabbed-big-tabs button.active,
.blk-tabbed-big-tabs button:hover {
	color: #38394B;
	border-bottom: 2px solid #38394b;
	transition: all 100ms ease-in-out;
}

.blk-tabbed-big-content{
	width: 100%;
	box-sizing: border-box;
	border-top:2px solid #f8f8f9;
}
.form-err{
	display: block;
	box-sizing: border-box;
	width: 100%;
	min-height: 35px;
	line-height: 1.3;
	padding: 10px 40px;
	text-align: center;
	font-size:11px;
	font-style: italic;
	color:#38394b;
}
.form-err.has-errors{
	color: #EC6653;
}
.form-err.has-success{
	color: #52bc75;
}
.blk-tabbed-big-content-slide{
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	min-height: 100px;
	padding-bottom: 40px;
}
.blk-tabbed-big-inside-left,
.blk-tabbed-big-inside-right{
	display: inline-block;
	box-sizing: border-box;
	width: 50%;
	padding-left:40px;
	padding-right:40px;
}
.blk-tabbed-big-inside-left{
	border-right: 1px solid #f5f5f7;
}
.inner-tab-title{
	display: block;
	width: 100%;
	line-height: 1;
	padding-bottom:36px;
	text-align: center;
	font-size:17px;
	color:#38394b;
}


/* ############## MY ACCOUNT ################# */

.page-myaccount .content{
	width: 70%;
	min-height: 400px;
	padding: 2% 10% 8%;
	margin: 0 auto;
}

/* ############## ACCOUNT SECURITY ################# */

.account-security .content{
	width: 803px;
	min-height: 400px;
	padding: 0 0 4%;
	margin-left: -402px;
	left: 50%;
	position: relative;
}

.account-security .content .form-err{
	font-weight: bold;
}


.set-new-pass-area input{
	margin-bottom: 12px;
}
.set-new-pass-area button{
	padding-top:14px;
	padding-bottom:14px;
	margin-top: 28px;
}

.sec-google-auth-pre-enable,
.sec-google-auth-disable{
	display: block;
	text-align: center;
}
.sec-google-auth-pre-enable-line-1{
	display: block;
	width: 100%;

}
.sec-google-auth-pre-enable-line-1 img,
.sec-google-auth-disable img{
	display: block;
	width: 84px;
	margin: 0 auto 40px;
}
.sec-google-auth-pre-enable-line-1 .sec-ga-pre-text,
.sec-google-auth-disable .sec-ga-pre-text{
	display: block;
	width: 100%;
	height: 43px;
	line-height: 1.2;
	text-align: left;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	font-size:11px;
	color:#38394B;
}
.sec-google-auth-pre-enable-line-1 .sec-ga-pre-text span,
.sec-google-auth-disable .sec-ga-pre-text span{
	display: inline-block;
	height: 16px;
	padding-right: 5px;
	font-size:11px;
	color: #52BA73;
	float:left;
}
.sec-google-auth-pre-enable-line-1 button.sp-form-button,
.sec-google-auth-disable button.sp-form-button{
	padding-top: 14px;
	padding-bottom: 14px;
	background-color: #52BA73;
}

.sec-google-auth-disable .sec-ga-pre-text span{
	color:#ED6754
}

.sec-google-auth-disable button.sp-form-button{
	background-color: #ED6754;
}


.sec-google-auth-enable{
	display: block;
	width:100%;
}
.sec-google-auth-enable-line-1{
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;
	vertical-align: top;
	width: 100%;
	height: 149px;
}
.sec-google-auth-enable-line-1 .sec-google-auth-enable-line-1-left{
	display: inline-block;
	flex:1 1 50%;
	vertical-align: top;
}
.sec-google-auth-enable-line-1 .sec-google-auth-enable-line-1-right{
	display: inline-block;
	flex:1 1 50%;
	vertical-align: top;
}
.sec-google-auth-enable-line-1 small{
	display: block;
	width:100%;
	padding-bottom:5px;
	font-size:11px;
	color:#38394b;
}
.sec-google-auth-enable-line-1 .sec-google-auth-enable-line-1-right small{
	padding-bottom: 30px;
}
.sec-google-auth-enable-line-2{
	display: block;
	width:320px;
	height: 60px;
}
.sec-google-auth-enable-line-2 small{
	display: block;
	width:155px;
	padding-bottom:5px;
	font-size:11px;
	color:#38394b;
}
.sec-google-auth-enable-line-2 form input{
	display: block;
	box-sizing: border-box;
	width:155px;
	height: 40px;
	line-height: 40px;
	padding:0 12px;
	margin-right: 10px;
	font-size:16px;
	border:1px solid #d8d8da;
	text-align: center;
	float:left;
}
.sec-google-auth-enable-line-2 form button{
	display: block;
	box-sizing: border-box;
	width:155px;
	height: 40px;
	line-height: 40px;
	padding-top:0;
	padding-bottom:0;
	font-size:14px;
	color:#ffffff;
	border:0;
	background-color: #52bc75;
	text-align: center;
	cursor: pointer;
	float:left;
}



/* ############## FOOOTER ################# */

footer {
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	padding: 12px 30px;
	border-top: 1px solid #F4F4F6;
	background-color: #FFF;
	position: absolute;
	bottom: 0;
}

.footer-copyrights,
.footer-links,
.footer-systemstat,
.footer-toggle-theme {
	display: inline-block;
	line-height: 24px;
	color: #8c9293;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
}

.footer-copyrights {
	flex: 1 1 140px;
	text-align: left;
}

.footer-links {
	flex: 2 1 90%;
	line-height: 21px;
	text-align: center;
}

.footer-links a {
	display: inline-block;
	text-decoration: none;
	color: #8c9293;
}

.footer-links a:hover {
	color: #000;
}

.footer-links .footer-dot {
	display: inline-block;
	height: 20px;
	padding-left: 20px;
	padding-right: 20px;
	color: #8c9293;
	font-size: 15px;
	font-family: 'Roboto', Arial, Verdana, sans-serif;
	position: relative;
	top: 0.5px;
}

.footer-systemstat {
	flex: 3 1 60px;
	line-height: 23px;
	border-right: 1px solid #f4f4f6;
	padding-right: 20px;
}

.footer-systemstat em {
	position: relative;
	padding-left: 10px;
	color: #6DD900;
	font-size: 13px;
	top: -1px;
}

.footer-toggle-theme {
	flex: 4 1 18px;
	padding-top: 3px;
	padding-left: 20px;
	text-align: right;
}

.footer-toggle-theme button {
	display: block;
	width: 17px;
	height: 17px;
	cursor: pointer;
	text-align: right;
	text-decoration: none;
	background: transparent url("/assets/ic/theme-day.png") no-repeat 0 0;
	background-size: 17px auto;
	float: right;
}

.footer-toggle-theme button:hover {
	opacity: 0.7;
}

html body div#root .hidden {
	display: none;
}