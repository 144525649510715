@media all and (min-width: 0px) and (max-width: 1600px) {

	aside{
		width:605px;
		height: 426px;
		top:68px;
		left:30px;
		position: absolute;
		background: none;
		z-index: 1000;
		box-shadow: 0 0 15px rgba(0,0,0,0.2);
	}
	html body div#root aside.hidden{
		display: none;
	}

	aside .aside-logo{
		display: none;
	}

	aside .aside-exchange{
		background-color: #FFF;
	}

	aside.visible{
		display: block;
	}
	aside.visible .aside-arrow{
		display: block;
		margin-bottom:-13px;
	}

	aside.visible .aside-arrow span{
		display: block;
		position: relative;
		top:-13px;
	}

	aside.visible .aside-exchange{
		height: 425px;
	}

	.aside-exchange .markets-filter-main-table {
		height: 378px;
	}

	.aside-exchange .markets-filter-main-table table {
		height: 377px;
	}

	.markets-filter-main-table table tbody{
		height: 351px;
	}

	.aside-exchange .markets-filters-hl .markets-filters-all{
		display: -webkit-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		min-height: 33px;
	}

	.aside-exchange .markets-filters-hl .markets-filters-all .markets-filter-main-markets{
		order:1;
	}

	.aside-exchange .markets-filters-hl .markets-filters-all .markets-filter-main-search{
		order:2;
		border-bottom:0;
		padding-bottom:9px;
	}

	.aside-exchange .markets-filter-main-markets button{
		padding-top:8px;
		padding-bottom:13px;
	}

	.markets-filter-main-table .add2fav{
		height: 20px;
	}

	.markets-filter-main-table .exch-pair-nm{
		height: 20px;
	}

	.aside-exchange .markets-filter-main-table table tr th:nth-child(1){
		width:187px;
	}
	.markets-filter-main-table .exch-pair-nm{
		width: 180px;
		height: 20px;
		line-height: 20px;
	}
	.aside-exchange .markets-filter-main-table table tr th:nth-child(2){
		width: 173px;
	}
	.aside-exchange .markets-filter-main-table table tr td:nth-child(2){
		width: 171px;
		height: 20px;
		line-height: 20px;
		text-align: left;
	}
	.aside-exchange .markets-filter-main-table table tr th:nth-child(3){
		width: 120px;
	}
	.aside-exchange .markets-filter-main-table table tr td:nth-child(3){
		width: 100px;
		height: 20px;
		line-height: 20px;
		text-align: left;
	}
	.aside-exchange .markets-filter-main-table table tr td .cu-price{
		text-align: left;
		height: 20px;
		line-height: 20px;
		padding:0;
	}
	.aside-exchange .markets-filter-main-table table tr td .cu-price em{
		text-align: left;
		height: 20px;
		line-height: 20px;
		padding:0;
		font-size:12px;
	}
	.aside-exchange .markets-filter-main-table table tr th:nth-child(4){
		width: 100px;
	}
	.aside-exchange .markets-filter-main-table table tr td:nth-child(4){
		height: 20px;
		line-height: 20px;
		padding-right: 10px;
		text-align: right;
	}



	.page-exchange section.wrapper{
		width: 100%;
	}

	.page-exchange section.wrapper .header-logo-area{
		display: inline-block;
	}

	.exchain-head-info-name .ehi-tgl-aside {
		display: inline-block;
	}
}