.notification-blk{
	display: block;
	width: 30%;
	min-height: 100px;
	background-color: #ffffff;
	border: 1px solid #8c9293;
	box-shadow: 0 0 10px #38394b;
	top:20px;
	left: 50%;
	margin-left:-15%;
	position: fixed;
	animation-name: notif-show;
	animation-duration: 1s;
	z-index: 2000;
}

.notification-blk.hide-me{
	top:-400px;
	animation-name: notif-hide;
	animation-duration: 1s;
}

@keyframes notif-show {
	from {top:-400px;}
	to {top: 20px;}
}
@keyframes notif-hide {
	from {top:20px;}
	to {top: -400px;}
}

.notification-head{
	display: block;
	box-sizing: border-box;
	width:100%;
	height: 30px;
	line-height: 30px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: right;
}
.notification-head button{
	display: inline-block;
	width: auto;
	height: auto;
	line-height: 1;
	padding: 5px 0 5px 5px;
	border:0;
	background:none;
	text-decoration: none;
	color: #38394b;
	cursor:pointer;
}
.notification-head button:hover{
	color:#8c9293;
}


.notification-body{
	display: block;
	box-sizing: border-box;
	width:100%;
	min-height: 50px;
	line-height: 30px;
	padding: 10px;
	text-align: center;
	font-size:13px;
}

.notification-blk.info .notification-body{
	color:#38394b;
}
.notification-blk.error .notification-body{
	color:red;
}
.notification-blk.success .notification-body{
	color:green;
}

.notification-footer{
	display: block;
	box-sizing: border-box;
	width:100%;
	min-height: 0;
	padding: 10px;
}